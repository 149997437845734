
export const GlobalAction = {
  /**
   * 프로필 수정 회사 보여줄지말지
   */
  ProfileEditVisibilityCompany: 'profile-edit-visibility-company',
  /**
   * 경력 정보 업데이트
   */
  ProfileEditUpdateCareerKeyword: 'profile-edit-update-career-keyword',
  /**
   * 이름 수정
   */
  ProfileEditUpdateName: 'profile-edit-update-name',
  /**
   * 직업 타이틀 수정
   */
  ProfileEditUpdateDisplayCareer: 'profile-edit-update-display-career',
  /**
   * 자기소개 수정
   */
  ProfileEditUpdateIntroduction: 'profile-edit-update-introduction',
  /**
   * 회사 인증 메일 발송
   */
  VerifyCompany: 'verify-company',
  /**
   * 설정
   */
  Setting: 'setting',
  /**
   * 책 검색
   */
  SearchBook: 'search-book',
  /**
   * 키워드 검색
   */
  SearchKeyword: 'search-keyword',
  /**
   * 코멘트 생성
   */
  CreateComment: 'create-comment',
  /**
   * 읽고 싶어요.
   */
  WantRead: 'want-read',
  /**
   * 내 전문분야 키워드는 필수로 선택됩니다. 
   */
  RequireCareerKeyword: 'require-career-keyword',
  /**
   * 알림 읽기
   */
  ReadNotification: 'read-notification',
  /**
   * 검색
   */
  SearchAll: 'search-all',
}